import React from 'react'
import { Box, IconButton, Link, makeStyles } from '@material-ui/core'
import { colors, respSectionPadding } from '../utils/theme'
import { useMobile } from '../utils/useMobile'
import { Container } from './Container'
import { Footer } from './Footer'
import CloseIcon from '@material-ui/icons/Close'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(() => ({
  mainLink: {
    display: 'flex',
    alignItems: 'center',
    color: colors.white,
    fontSize: '26px',
    fontWeight: 600,
    '&:active, &:hover': {
      textDecoration: 'none'
    }
  },
  subLink: {
    color: colors.white,
    lineHeight: 1.6
  }
}))

export function ExtendedMenu ({ menuMap, menus, onClose }) {
  const mobile = useMobile()
  const {
    api: { data }
  } = useCoreData()

  const sortedMenus = !data.extended_menu
    ? []
    : data.extended_menu.submenus.reduce((acc, menu) => {
        if (acc[menu.append_to]) {
          acc[menu.append_to].push(menu)
        } else {
          acc[menu.append_to] = [menu]
        }
        return acc
      }, {})

  // TODO: IF URL

  return (
    <Box
      bgcolor='rgb(50,50,50)'
      height='100vh'
      width='100vw'
      zIndex={9998}
      position='fixed'
      color={colors.white}
      pt={respSectionPadding(true) + 'px'}
      pl={mobile ? '32px' : ''}
      pr={mobile ? '12px' : ''}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Container unwrap={mobile}>
        <Box
          position='relative'
          {...(!mobile
            ? {
                display: 'flex',
                justifyContent: 'space-between'
              }
            : {})}
        >
          <Box
            ml='auto'
            width='fit-content'
            {...(!mobile
              ? {
                  position: 'absolute',
                  right: '-12px',
                  top: '-12px'
                }
              : {})}
          >
            <IconButton color='inherit' onClick={onClose} disableRipple>
              <CloseIcon />
            </IconButton>
          </Box>
          {Object.entries(menus).map(([key, value]) => (
            <Section
              key={value}
              value={value}
              path={menuMap[key]}
              menus={sortedMenus[key]}
            />
          ))}
        </Box>
      </Container>
      {!mobile && <Footer />}
    </Box>
  )
}

function Section ({ value, path, menus = [] }) {
  const classes = useStyles()
  return (
    <Box mt='30px'>
      <Link
        href={path}
        classes={{
          root: classes.mainLink
        }}
      >
        {value}
      </Link>
      <Box display='flex' flexDirection='column' mt='12px'>
        {menus.map(m => (
          <Link
            key={m.section_id}
            href={`${path}#${m.section_id}`}
            classes={{
              root: classes.subLink
            }}
          >
            {m.name}
          </Link>
        ))}
      </Box>
    </Box>
  )
}
