import { useEffect, useState } from 'react'
import { getWindow } from './getWindow'

export const languages = [
  ['EN', 'en-gb'],
  ['PT', 'pt-br'],
  ['ES', 'es-es']
]

export function useLanguage () {
  const win = getWindow()
  const [language, setLanguage] = useState(
    (win && localStorage.getItem('language')) || 'en-gb'
  )

  useEffect(() => {
    if (!win) return
    const chosenLanguage = localStorage.getItem('language')
    if (chosenLanguage) {
      setLanguage(chosenLanguage)
    } else {
      const browserLanguage = win?.navigator?.language?.split('-')[0]
      const match = languages.find(
        lan => lan[0].toLowerCase() === browserLanguage.toLowerCase()
      )
      setLanguage((match && match[1]) || 'en-gb')
    }
  }, [win])

  function change (lang) {
    localStorage.setItem('language', lang)
    setLanguage(lang)
  }

  return {
    language,
    change
  }
}
