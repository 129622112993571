import React, { createContext, useContext, useMemo } from 'react'
const context = createContext({})
const { Provider } = context

const KEYS = ['api']

function getLatestValue (props, parent, key) {
  return typeof props[key] === 'undefined' ? parent[key] : props[key]
}

export function CoreDataProvider (props) {
  const parent = useCoreData()
  const deps = KEYS.map(key => getLatestValue(props, parent, key))
  const mergedData = useMemo(() => {
    const merged = {}
    for (const key of KEYS) {
      merged[key] = getLatestValue(props, parent, key)
    }
    return merged
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  return <Provider value={mergedData}>{props.children}</Provider>
}

export function useCoreData () {
  return useContext(context)
}
