import React from 'react'
export function Logo () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86.018" height="34.604" viewBox="0 0 86.018 34.604">
      <g id="Layer_1_1_" transform="translate(-214.1 -429.7)">
        <path id="Path_121" data-name="Path 121" d="M297.4,429.8v29.244h29.2v-9.357H306.757V429.8Z" transform="translate(-70.769 -0.085)" fill="#1d1d1b"/>
        <path id="Path_122" data-name="Path 122" d="M395.329,446.829V429.8H378.3v17.029h5.175V442.09h6.8v4.739Zm-5.175-11.147a3.279,3.279,0,1,1-3.279-3.279,3.277,3.277,0,0,1,3.279,3.279" transform="translate(-139.499 -0.085)" fill="#1d1d1b"/>
        <rect id="Rectangle_106" data-name="Rectangle 106" width="9.432" height="18.488" transform="translate(214.1 440.471)" fill="#1d1d1b"/>
        <path id="Path_123" data-name="Path 123" d="M607.56,429.715h-5.476v5.747a2.382,2.382,0,0,1-.075.572c0,.03-.015.075-.015.105a3.19,3.19,0,0,1-2.061,2.317h0c-.15.045-.3.09-.451.12a2.8,2.8,0,0,1-.511.06,3.021,3.021,0,0,1-1.459-.391,2.983,2.983,0,0,1-.707-.527,2.894,2.894,0,0,1-.5-.692,2.289,2.289,0,0,1-.286-.647,12.8,12.8,0,0,1-.15-2.708V429.7H591.93l-.03,1.369v5.009l.015,8.53a14.609,14.609,0,0,0,29.109,1.5h-9.583a5.366,5.366,0,1,1,.241-3.009h9.4a14.651,14.651,0,0,0-13.524-13.389" transform="translate(-320.966 0)" fill="#1d1d1b"/>
        <path id="Path_124" data-name="Path 124" d="M223.821,437.542A3.821,3.821,0,1,0,220,433.736a3.826,3.826,0,0,0,3.821,3.806" transform="translate(-5.012 -0.17)" fill="#1d1d1b"/>
        <path id="Path_125" data-name="Path 125" d="M482.891,459.12c.346-3.46.241-29.32.241-29.32h9.522s-.015,23.408-.105,25.965c-.135,4.167-2.738,8.364-9.327,8.575-7.371.226-4.182-.391-4.047-.451a5.647,5.647,0,0,0,3.716-4.769" transform="translate(-224.518 -0.085)" fill="#1d1d1b"/>
      </g>
    </svg>
    )
}
