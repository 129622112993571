import LatoBlack from '../fonts/Lato-Black.ttf'
import LatoBlackItalic from '../fonts/Lato-BlackItalic.ttf'
import LatoBold from '../fonts/Lato-Bold.ttf'
import LatoBoldItalic from '../fonts/Lato-BoldItalic.ttf'
import LatoRegular from '../fonts/Lato-Regular.ttf'
import LatoItalic from '../fonts/Lato-Italic.ttf'
import LatoLight from '../fonts/Lato-Light.ttf'
import LatoLightItalic from '../fonts/Lato-LightItalic.ttf'
import LatoThin from '../fonts/Lato-Thin.ttf'
import LatoThinItalic from '../fonts/Lato-ThinItalic.ttf'
import NewYork from '../fonts/NewYork.otf'

import { createMuiTheme } from '@material-ui/core'

const latoFonts = [
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 900,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Black'),
    url(${LatoBlack})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 900,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-BlackItalic'),
    url(${LatoBlackItalic})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Bold'),
    url(${LatoBold})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 700,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-BoldItalic'),
    url(${LatoBoldItalic})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Regular'),
    url(${LatoRegular})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 400,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Italic'),
    url(${LatoItalic})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 300,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Light'),
    url(${LatoLight})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 300,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-LightItalic'),
    url(${LatoLightItalic})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 100,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-Thin'),
    url(${LatoThin})
  `
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 100,
    fontDisplay: 'swap',
    src: `
    local('Lato'),
    local('Lato-ThinItalic'),
    url(${LatoThinItalic})
  `
  }
]

const newYorkFonts = [
  {
    fontFamily: 'NewYork',
    src: `
    local('NewYork'),
    url(${NewYork})
  `
  }
]

export const colors = {
  green: 'rgb(67, 183, 119)',
  lightBlue: 'rgb(130, 205, 217)',
  darkBlue: 'rgb(70, 88, 174)',
  red: 'rgb(237, 77, 31)',
  yellow: 'rgb(235, 224, 51)',
  white: 'rgb(255,255,255)',
  lightGrey: 'rgb(236,236,228)',
  darkGrey: 'rgb(78,78,74)',
  black: 'rgb(42,42,40)'
}

export const sectionPadding = 90

export const respSectionPadding = small =>
  small ? sectionPadding / 2 : sectionPadding

export const toolbarHeight = 64

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, Arial'
  },
  palette: {
    text: { primary: colors.black, secondary: colors.darkGrey },
    primary: {
      main: colors.darkBlue
    },
    secondary: {
      main: colors.yellow
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...latoFonts, ...newYorkFonts],
        body: {
          backgroundColor: 'white',
          '-webkit-font-smoothing': 'subpixel-antialiased',
          '& strong': {
            fontWeight: 600
          }
        },
        p: {
          margin: 0
        },
        a: {
          color: 'inherit'
        },
        h4: {
          fontWeight: 600
        },
        '[tabindex]': {
          outline: 'none'
        }
      }
    },
    MuiButton: {
      root: {
        backgroundColor: colors.black,
        color: colors.white,
        textDecoration: 'underline',
        textTransform: 'none'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparen'
        }
      }
    },
    MuiTextField: {
      root: {
        '& label.MuiFormLabel-root.Mui-focused': {
          color: colors.black
        },
        '& div': {
          '&:after, &:before': {
            borderColor: colors.black
          }
        },
        '& fieldset': {
          borderColor: `${colors.black} !important`
        }
      }
    },
    MuiLink: {
      root: {
        color: colors.black
      }
    }
  }
})
