import { useEffect, useState } from 'react'
import { useCoreData } from './useCoreData'
import { getWindow } from './getWindow'

const cache = {
  'en-gb': {},
  'pt-br': {},
  'es-es': {},
  '*': {
    news: []
  }
}

export function useApiCall (types, lang) {
  const [results, setResults] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { api } = useCoreData()
  const language = lang || api.language
  useEffect(() => {
    if (typeof types === 'string') types = [types]
    const cachedResults = getCachedResults(types, language)
    const hasResults = types.some(type => cachedResults[type])
    if (hasResults) {
      setResults(cachedResults)
    } else {
      const win = getWindow()
      win.ilajucCache = cache
      setLoading(true)
      win
        .fetch(`/.netlify/functions/content`)
        .then(res => res.json())
        .then(res => {
          const news = res.results.filter(r => r.type === 'news')
          const publications = res.results.filter(r => r.type === 'publications')

          res.results.forEach(result => {
            if (result.type === 'news' || result.type === 'publications') return
            cache[result.lang][result.type] = result.data
          })
          news.forEach(n => {
            if (cache[n.lang].news) {
              cache[n.lang].news.push(n)
            } else {
              cache[n.lang].news = [n]
            }
            cache['*'].news.push(n)
          })
          publications.forEach(n => {
            if (cache[n.lang].publications) {
              cache[n.lang].publications.push(n)
            } else {
              cache[n.lang].publications = [n]
            }
          })

          setResults(getCachedResults(types, language))
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
          setError(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])
  return { results, allNews: cache['*'].news, loading, error }
}

function getCachedResults (types, language) {
  return types.reduce(
    (acc, type) => ({ ...acc, [type]: cache[language][type] }),
    {}
  )
}
