import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { colors } from '../utils/theme'
import { cx } from '../utils/cx'
import { getRandomInt } from '../utils/getRandomInt'

const useStyles = makeStyles(() => ({
  green: {
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px'
  },
  yellow: {
    borderTopRightRadius: '40px'
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderRadius: '100px',
    '&:before': {
      content: "''",
      display: 'block',
      paddingTop: '100%'
    }
  },
  eyelid: {
    transition: 'all .5s ease-out',
    zIndex: 9999,
    '&:before': {
      content: "''",
      display: 'block',
      paddingTop: '55%'
    }
  },
  top: {
    transform: 'translateY(-20px)',
    borderTopLeftRadius: '100px',
    borderTopRightRadius: '100px'
  },
  bottom: {
    transform: 'translateY(20px)',
    borderBottomLeftRadius: '100px',
    borderBottomRightRadius: '100px'
  },
  blinkingTop: {
    transform: 'translateY(0)',
    transition: 'transform .25s ease-in'
  },
  blinkingBottom: {
    transform: 'translateY(0)',
    transition: 'transform .25s ease-in'
  }
}))

export function Toucan ({ animated }) {
  const classes = useStyles()
  const [blinking, setBlinking] = useState(false)

  const blink = () => {
    setBlinking(prev => !prev)
  }

  useEffect(() => {
    if (!animated) return
    if (!blinking) {
      setTimeout(blink, 2000)
    }
  }, [])

  useEffect(() => {
    if (!animated) return
    const times = [10000, 8000, 8000, 9000, 9000]
    const timeout = times[getRandomInt(5)]
    if (!blinking) {
      setTimeout(blink, timeout)
    }
  }, [blinking])

  return (
    <Box
      display='flex'
      alignItems='flex-end'
      className='toucan-wrapper'
      onMouseOver={e => {
        if (!e.target.classList.contains('toucan-wrapper') && !blinking) {
          blink()
        }
      }}
    >
      <Box
        position='relative'
        bgcolor={colors.green}
        width='55px'
        height='55px'
        className={classes.green}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Box
          bgcolor={colors.darkBlue}
          width='80%'
          className={classes.circle}
          overflow='hidden'
        >
          <Box
            bgcolor={colors.black}
            borderRadius='100px'
            width='55%'
            className={classes.circle}
          >
            <Box
              bgcolor={colors.white}
              width='35%'
              className={classes.circle}
            />
          </Box>
          <Box
            position='absolute'
            width='55%'
            zIndex={9999999}
            overflow='hidden'
          >
            <Box
              bgcolor={colors.darkBlue}
              width='100%'
              className={cx(
                classes.eyelid,
                classes.top,
                blinking && classes.blinkingTop
              )}
            />
            <Box
              bgcolor={colors.darkBlue}
              width='100%'
              className={cx(
                classes.eyelid,
                classes.bottom,
                blinking && classes.blinkingBottom
              )}
              onTransitionEnd={() => {
                setTimeout(() => {
                  setBlinking(false)
                }, 500)
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        bgcolor={colors.yellow}
        width={`${55 / 2 + 55 * 1.7}px`}
        ml={`-${55 / 2}px`}
        height='40px'
        className={classes.yellow}
      />
    </Box>
  )
}
