import { Box, IconButton, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { colors } from '../utils/theme'
import CloseIcon from '@material-ui/icons/Close'
import { getWindow } from '../utils/getWindow'
import { NewsletterSignUpForm } from './NewsletterSignUpForm'
import { Container } from './Container'
import { Toucan } from './Toucan'
import { useMobile } from '../utils/useMobile'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(theme => ({
  '@keyframes newsletterPopUp': {
    '0%': { opacity: 0 },
    '90%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  closeButton: {
    fontSize: '18px'
  },
  popUp: {
    animation: `$newsletterPopUp 4s ${theme.transitions.easing.easeInOut}`
  }
}))

export function NewsletterPopUp () {
  const classes = useStyles()
  const win = getWindow()
  const mobile = useMobile()
  const {
    api: {
      data: { miscellaneous }
    }
  } = useCoreData()

  const [newsletterClosed, setNewsletterClosed] = useState(false)
  function handleClose () {
    win && sessionStorage.setItem('newsletter_closed', true)
    setNewsletterClosed(true)
  }

  useEffect(() => {
    win && setNewsletterClosed(sessionStorage.getItem('newsletter_closed'))
  }, [win])

  if (newsletterClosed || mobile || !miscellaneous) return null

  return (
    <Container className={classes.popUp}>
      <Box position='absolute' bottom='6px' right='0'>
        <Toucan animated />
        <Box bgcolor={colors.white} width='360px' border='1px solid black'>
          <Box position='relative' p='20px' pb='10px'>
            <NewsletterSignUpForm
              compact
              onSuccess={() => setTimeout(handleClose, 2000)}
              data={miscellaneous}
            />
            <Box
              position='absolute'
              top='-36px'
              right={0}
              bgcolor={colors.darkGrey}
              color={colors.white}
              borderRadius='100px'
            >
              <IconButton
                color='inherit'
                onClick={handleClose}
                disableRipple
                size='small'
              >
                <CloseIcon className={classes.closeButton} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
