import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { colors } from '../utils/theme'
import { Container } from './Container'
import CloseIcon from '@material-ui/icons/Close'
import { getWindow } from '../utils/getWindow'

const useStyles = makeStyles(() => ({
  withTransform: {
    transform: 'translateY(-50%)'
  }
}))

export function CookieBanner () {
  const classes = useStyles()
  const win = getWindow()
  const [acceptedCookies, setAcceptedCookies] = useState(false)
  function handleClose () {
    win && sessionStorage.setItem('accepted_cookies', true)
    setAcceptedCookies(true)
  }

  useEffect(() => {
    win && setAcceptedCookies(sessionStorage.getItem('accepted_cookies'))
  }, [win])

  if (acceptedCookies) return null

  return (
    <Box
      bgcolor={colors.darkGrey}
      color={colors.white}
      width='100%'
      textAlign='center'
      py='24px'
    >
      <Container>
        <Box pr='24px'>
          <Typography>
            We use cookies for analytics and to improve your experience. You
            accept our use of cookies by closing this message box or continuing
            to use our site.
          </Typography>
        </Box>
        <Box
          position='absolute'
          right={0}
          top='50%'
          className={classes.withTransform}
        >
          <IconButton color='inherit' onClick={handleClose} disableRipple>
            <CloseIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  )
}
