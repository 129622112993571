import {
  Box,
  Container as DefaultContainer,
  makeStyles
} from '@material-ui/core'
import React from 'react'
import { cx } from '../utils/cx'
import { useMobile } from '../utils/useMobile'
import { toolbarHeight } from '../utils/theme'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  },
  withBottomPadding: {
    paddingBottom: '90px'
  },
  mobilePadding: {
    paddingLeft: '32px',
    paddingRight: '32px'
  }
}))

export function Container ({
  children,
  unwrap,
  withToolbar,
  withBottomPadding,
  className
}) {
  const mobile = useMobile()
  const classes = useStyles()
  if (unwrap) return children
  return (
    <DefaultContainer
      classes={{
        root: cx(
          classes.container,
          withBottomPadding && classes.withBottomPadding,
          mobile && classes.mobilePadding,
          className
        )
      }}
    >
      {withToolbar && <Box height={`${toolbarHeight + 28}px`} mb='20px' />}
      {children}
    </DefaultContainer>
  )
}

export default Container
