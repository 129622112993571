import {
  Box,
  CircularProgress,
  Button,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { colors } from '../utils/theme'
import { getWindow } from '../utils/getWindow'
import { cx } from '../utils/cx'
import { Toucan } from './Toucan'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(() => ({
  success: {
    color: colors.green
  },
  input: {
    fontStyle: 'italic',
    fontFamily: 'Bodoni Moda',
    '& ::placeholder': {
      opacity: 1,
      fontStyle: 'italic',
      fontFamily: 'Bodoni Moda'
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  title: {
    fontWeight: 900
  },
  largeTitle: {
    fontSize: '3em'
  },
  button: {
    backgroundColor: colors.red,
    color: colors.white,
    borderRadius: 0,
    padding: '8px 15px',
    transition: 'all 0.5s',
    '&.Mui-disabled': {
      color: colors.white,
      opacity: 0.7
    }
  },
  circularProgress: {
    position: 'absolute'
  },
  hidden: {
    opacity: 0
  },
  toucan: {
    transform: 'scaleX(-1)'
  }
}))

const initialStatus = {
  loading: false,
  success: false,
  fail: false
}

export function NewsletterSignUpForm ({ onSuccess, compact, data }) {
  const [{ loading, success, fail }, setStatus] = useState(initialStatus)
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const { api } = useCoreData()

  const {
    newsletter_error,
    newsletter_invalid_email,
    newsletter_placeholder,
    newsletter_sign_up,
    newsletter_subtitle,
    newsletter_success,
    newsletter_title
  } = data

  const handleReset = () => setStatus(initialStatus)
  const handleLoading = () => setStatus({ ...initialStatus, loading: true })
  const handleFail = message =>
    setStatus({ ...initialStatus, fail: message || true })
  const handleSuccess = () => {
    setStatus({ ...initialStatus, success: true })
    onSuccess && onSuccess()
  }

  function handleClick () {
    handleLoading()
    const win = getWindow()

    win
      .fetch(
        `/.netlify/functions/form?email=${email}&language=${
          api.language.split('-')[0]
        }`
      )
      .then(res => {
        if (res.ok) {
          handleSuccess()
        } else {
          handleFail(
            res.status === 400 ? newsletter_invalid_email : newsletter_error
          )
        }
      })
  }

  return (
    <Box textAlign='left'>
      <Box mb='15px' display='flex' flexDirection='column'>
        <Typography
          className={cx(classes.title, !compact && classes.largeTitle)}
          variant={compact ? 'h6' : 'h3'}
        >
          {newsletter_title}
        </Typography>
        <Typography
          className={classes.subtitle}
          variant={compact ? 'body1' : 'h6'}
        >
          {newsletter_subtitle}
        </Typography>
      </Box>
      {!compact && (
        <Box borderBottom={`1px solid ${colors.darkGrey}`} mb='32px' mt='12px'>
          <Box classes={{ root: classes.toucan }}>
            <Toucan />
          </Box>
        </Box>
      )}
      <Box display='flex' justifyContent='space-between' alignItems='baseline'>
        <Box flex='1' pr='10%'>
          <TextField
            type='email'
            fullWidth
            disabled={loading}
            onChange={e => {
              handleReset()
              setEmail(e.target.value)
            }}
            value={email}
            placeholder={newsletter_placeholder}
            className={classes.input}
          />
          <Box minHeight='2em'>
            {fail && (
              <Typography variant='caption' color='error'>
                {fail}
              </Typography>
            )}
            {success && (
              <Typography variant='caption' className={classes.success}>
                {newsletter_success}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          {loading && (
            <CircularProgress size={25} className={classes.circularProgress} />
          )}
          <Button
            disabled={!email}
            onClick={handleClick}
            className={cx(classes.button, loading && classes.hidden)}
          >
            {newsletter_sign_up}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
