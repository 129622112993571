import { getWindow } from './getWindow'

export function scrollOnLoad (tries = 0) {
  const win = getWindow()
  const hash = win?.location.hash
  if (!hash) return
  if (tries < 11) {
    const el = typeof document !== 'undefined' && document.querySelector(hash)
    if (el) {
      win.history.pushState(null, null, ' ')
      win.scrollTo(0, el.parentElement.offsetTop - 50)
    } else {
      setTimeout(() => {
        scrollOnLoad(tries + 1)
      }, 100)
    }
  }
}
