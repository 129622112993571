import {
  AppBar,
  Box,
  IconButton,
  Link,
  makeStyles,
  Toolbar
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useEffect, useState } from 'react'
import { Logo } from '../assets/logo-horizontal'
import { cx } from '../utils/cx'
import { useMobile } from '../utils/useMobile'
import { colors } from '../utils/theme'
import { Container } from './Container'
import { useMediumScreen } from '../utils/useMediumScreen'
import { getWindow } from '../utils/getWindow'
import { ExtendedMenu } from './ExtendedMenu'
import { useCoreData } from '../utils/useCoreData'

const activeLink = {
  '&:after': {
    content: '""',
    width: '100%',
    backgroundColor: colors.yellow,
    height: '2px',
    position: 'absolute',
    bottom: 0,
    left: 0
  }
}
const useStyles = makeStyles(() => ({
  toolbar: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingRight: 0
  },
  mobileToolbar: {
    justifyContent: 'flex-end'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: colors.black,
    position: 'relative',
    marginRight: '12px',
    '&:active, &:hover': {
      textDecoration: 'none',
      ...activeLink
    }
  },
  darkThemeLink: {
    color: colors.white
  },
  activeLink: {
    ...activeLink
  },
  button: {
    paddingRight: 0
  },
  borderedHeader: {
    borderBottom: `1px solid ${colors.lightGrey}`
  }
}))

const menuMap = {
  contact: '/contact',
  news: '/news',
  publications: '/publications',
  infographics: 'https://infografico.ilajuc.org/',
  what_we_do: '/our-work',
  who_we_are: '/about-us'
}

export function Header ({ setHeaderHeight = () => {}, dark }) {
  const win = getWindow()
  const [currentPath, setCurrentPath] = useState('')
  const [extended, setExtended] = useState(false)
  const classes = useStyles()
  const mobile = useMobile()
  const mediumScreen = useMediumScreen()
  const {
    api: { data, loading }
  } = useCoreData()

  useEffect(() => {
    setCurrentPath(win?.location?.pathname)
  }, [win])

  if (!data.header && loading) {
    return null
  }

  const menus = data.header

  return (
    <>
      <AppBar
        position='absolute'
        color='transparent'
        elevation={0}
        ref={el => {
          el && setHeaderHeight(el.offsetHeight)
        }}
        classes={{ root: !dark && classes.borderedHeader }}
      >
        <Container>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            pt='14px'
            pb={dark ? '14px' : ''}
          >
            <Box display='flex' alignItems='center' position='static'>
              <Link href='/'>
                <Logo />
              </Link>
            </Box>
            <Toolbar
              classes={{
                root: cx(classes.toolbar, mediumScreen && classes.mobileToolbar)
              }}
            >
              {!mediumScreen &&
                Object.entries(menus).map(([key, value]) => (
                  <Link
                    key={key}
                    href={menuMap[key]}
                    classes={{
                      root: cx(
                        classes.link,
                        dark && classes.darkThemeLink,
                        currentPath.includes(menuMap[key]) && classes.activeLink
                      )
                    }}
                  >
                    {value}
                  </Link>
                ))}
              <IconButton
                classes={{
                  root: classes.button
                }}
                onClick={() => setExtended(true)}
                disableRipple
              >
                <MenuIcon
                  htmlColor={dark && !mobile ? colors.white : colors.black}
                />
              </IconButton>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
      {extended && (
        <ExtendedMenu
          onClose={() => setExtended(false)}
          menuMap={menuMap}
          menus={menus}
        />
      )}
    </>
  )
}
