import React, { useState } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { colors, theme } from '../utils/theme'
import { CssBaseline, Link, makeStyles, useMediaQuery } from '@material-ui/core'
import { Header } from './Header'
import { languages, useLanguage } from '../utils/useLanguage'
import { CoreDataProvider } from '../utils/useCoreData'
import { Footer } from './Footer'
import { cx } from '../utils/cx'
import { Helmet } from 'react-helmet'
import { CookieBanner } from './CookieBanner'
import { scrollOnLoad } from '../utils/scrollOnLoad'
import { NewsletterPopUp } from './NewsletterPopUp'
import { useApiCall } from '../utils/api'

const useStyles = makeStyles(() => ({
  link: { marginLeft: '14px' },
  white: { color: colors.white },
  black: { color: colors.black },
  languageWrapper: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: '100%',
    zIndex: '9997',
    flexDirection: 'row',
    flexBasis: '100%'
  },
  mobile: {
    marginTop: '8px'
  },
  desktop: {
    marginTop: '14px'
  },
  pageWrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  banners: {
    zIndex: 9999,
    position: 'fixed',
    bottom: 0,
    width: '100%'
  }
}))

scrollOnLoad()

export function Page ({ children, index }) {
  const { language, change } = useLanguage()
  const [headerHeight, setHeaderHeight] = useState(0)
  const classes = useStyles()
  const mobile = useMediaQuery('(max-width:600px)')
  const { loading, results, allNews } = useApiCall(
    [
      'header',
      'extended_menu',
      'miscellaneous',
      'news',
      'our_work',
      'about_us',
      'contact',
      'homepage',
      'publications'
    ],
    language
  )

  return (
    <ThemeProvider theme={theme}>
      <CoreDataProvider
        api={{
          language,
          change,
          headerHeight,
          data: results,
          allNews,
          loading
        }}
      >
        <Helmet
          htmlAttributes={{
            lang: language.split('-')[0]
          }}
        >
          <title>ILAJUC - Instituto Latino-americano para Justiça Coletiva</title>
          <meta
            name='description'
            content='ILAJUC works to develop and improve the rights of people in Latin America to get access to justice across the world through collective redress. We want to make the world a better and fairer place.'
          />
          <style>
            @import
            url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;600;900&display=swap");
          </style>
        </Helmet>
        <CssBaseline />
        <div className={classes.pageWrapper}>
          <div
            className={cx(
              classes.languageWrapper,
              mobile ? classes.mobile : classes.desktop
            )}
          >
            {languages.map(([text, id]) => (
              <Link
                href=''
                key={id}
                classes={{
                  root: cx(
                    classes.link,
                    index && !mobile ? classes.white : classes.black
                  )
                }}
                onClick={e => {
                  e.preventDefault()
                  change(id)
                }}
              >
                {text}
              </Link>
            ))}
          </div>
          <Header setHeaderHeight={setHeaderHeight} dark={index} />
          {children}
          <Footer />
        </div>
        <div className={classes.banners}>
          <NewsletterPopUp />
          <CookieBanner />
        </div>
      </CoreDataProvider>
    </ThemeProvider>
  )
}
